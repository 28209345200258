<template>
  <div>
    <div class="lg:flex lg:space-x-10">
      <div class="w-full lg:px-20 space-y-7">
        <div class="card mt-4 p-3">
          <div>
            <h2 class="text-xl font-semibold">Thể loại: {{ fcat.title }} ({{ fcat.folks.paginatorInfo.total }} bài)</h2>
          </div>

          <div class="grid md:grid-cols-2 divide divide-gray-200 gap-x-4 mt-4" v-if="fcat.folks.data.length">
            <song-item v-for="song in fcat.folks.data" :song="song" prefix="dan-ca" :key="'song' + song.id"/>
          </div>
          <div class="mt-4">
            <v-pagination
                class="justify-content-center"
                v-if="fcat.folks.paginatorInfo.lastPage > 1"
                v-model="fcat.folks.paginatorInfo.currentPage"
                :pages="fcat.folks.paginatorInfo.lastPage"
                :range-size="1"
                active-color="#DCEDFF"
                @update:modelValue="loadFcat"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ApiService from "../../core/services/api.service";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import SongItem from "../SongItem";
import { changePageTitle } from "../../core/services/utils.service";

export default {
  name: "FcatDetail",
  components: {
    VPagination,
    SongItem
  },
  data() {
    return {
      slug: "",
      fcat: {
        title: "",
        slug: "",
        folks: {
          data: [],
          paginatorInfo: {
            currentPage: 1,
            lastPage: 0,
            total: 0
          }
        }
      }
    }
  },
  methods: {
    loadFcat() {
      let query = `query($slug: String!, $page: Int) {
        fcat(slug: $slug) {
          id
          title
          slug
          views
          folks(first: 20, page: $page, orderBy: [{column: "id", order: DESC}]) {
            data {
              id
              slug
              title
              thumbnail {
              url
            }
              artists(first: 5) {
                data {
                  id
                  slug
                  title
                }
              }
            },
            paginatorInfo {
              currentPage
              lastPage
              total
            }
          }
        }
      }`;

      ApiService.graphql(query, {slug: this.slug, page: this.fcat.folks.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.fcat) {
              this.fcat = data.data.fcat;
              changePageTitle("Thể loại: " + this.fcat.title);
            }
          })
          .catch((response) => {
            console.log(response);
          });
    }
  },
  mounted() {
    this.slug = this.$route.params.slug;
    this.loadFcat();
  },
  beforeRouteUpdate(to, from, next) {
    this.slug = to.params.slug;
    this.loadFcat();
    window.scrollTo(0, 0);
    next();
  }
}
</script>
